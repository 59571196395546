import request from '@/utils/request'

// 预约项目列表
export function makeList() {
    return request({
        url: '/appoint/lst',
        method: 'get'
    })
}

// 预约项目添加
export function makeAdd(params) {
    return request({
        url: '/appoint/add',
        method: 'POST',
		params:params
    })
}

// 预约项目删除
export function makeDel(params) {
    return request({
        url: '/appoint/del',
        method: 'DELETE',
		params:params
    })
}

// 预约项目详情
export function makeInfo(params) {
    return request({
        url: '/appoint/info',
        method: 'GET',
		params:params
    })
}

// 预约项目编辑
export function makeEdit(params) {
    return request({
        url: '/appoint/edit',
        method: 'POST',
		params:params
    })
}

// 用户预约列表
export function userAppoint(params) {
    return request({
        url: '/appoint/userAppoint',
        method: 'GET',
		params:params
    })
}




export function uploadImage(data) {
    return request({
        url: '/upload/image/one',
        method: 'post',
        data: data
    })
}


// 
export function info(params) {
    return request({
        url: '/info',
        method: 'GET',
		params:params
    })
}

// 编辑门店状态
export function changest(params) {
    return request({
        url: '/changest',
        method: 'POST',
		params:params
    })
}