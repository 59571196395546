<template>
	<div class="make">

		<div>
			<van>
				<van-list style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
					<div v-for="(item,index) in goodsList" :key="index" class="goods-item">
						<van-swipe-cell>
							<div class="abc">
								<div>
									<img :src="imageUrl+item.image" alt="暂无图片" style="width: 50px;height: 50px;" />
								</div>
								<div>
									<p>预约名称:{{item.name}}</p>
									<p>说明:{{item.intro}}</p>
								</div>
								<div class="switvh">
									<p>预约开关</p>
									<van-switch v-model="item.status" active-color="#ee0a24" size="20" :active-value="1"
										:inactive-value="0" />
								</div>
							</div>
							<template #right>
								<van-button square type="primary" text="编辑" @click='detail(item.id)' />
								<van-button square type="danger" text="删除" @click='del(item.id)' />
								<van-button square type="success" text="预约列表" @click='AnList(item.id)' />
							</template>
						</van-swipe-cell>
					</div>
				</van-list>
				<van-empty description="暂无预约" v-else />
			</van>
		</div>
		<div class="top">
			<button @click="addMake" class="add">添加</button>
			<div style="display: flex;align-items: center;color: #646566;margin-left: 15px;">
				<p style="margin: 0; margin-right: 10px;">预约开关</p>
				<van-switch v-model="formdata.is_appoint" active-color="#ee0a24" size="20" :active-value="1"
					:inactive-value="0" @change="statusVals(value)" />
			</div>
		</div>
		<van-popup v-model:show="add">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="名称" label-width="3rem" size="large" clearable v-model="formdata.name" name="name"
					placeholder="请输入预约项目名称" :rules="[{ required:true, message: '请输入预约项目名称' }]" />
				<van-field label="说明" size="large" label-width="3rem" clearable v-model="formdata.intro" name="intro"
					placeholder="请输入说明" :rules="[{ required:true, message: '请输入说明' }]" />
				<van-field name="uploader" label="图片" :rules="[{ required: true, message: '请选择图片' }]">
					<template #input>
						<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
							v-model="image" />
					</template>
				</van-field>
				<div style="display: flex;align-items: center;color: #646566;margin-left: 15px;">
					<p style="margin: 0; margin-right: 10px;">预约开关</p>
					<van-switch v-model="formdata.status" active-color="#ee0a24" size="20" :active-value="1"
						:inactive-value="0" @change="statusVal(value)" />
				</div>
				<div style="margin-top:2rem;">
					<button
						style="width: 100%;height: 40px;background-color: #ee0a24;border: 1px solid #ee0a24;border-radius: 20px;color: #FFFFFF;"
						@click='addList'>添加</button>
				</div>
			</van-form>
		</van-popup>
		<van-popup v-model:show="info">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="名称" label-width="3rem" size="large" clearable v-model="formdata.name" name="name"
					placeholder="请输入预约项目名称" :rules="[{ required:true, message: '请输入预约项目名称' }]" />
				<van-field label="说明" size="large" label-width="3rem" clearable v-model="formdata.intro" name="intro"
					placeholder="请输入说明" :rules="[{ required:true, message: '请输入说明' }]" />
				<van-field name="uploader" label="图片" :rules="[{ required: true, message: '请选择图片' }]">
					<template #input>
						<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
							v-model="image" />
					</template>
				</van-field>
				<div style="display: flex;align-items: center;color: #646566;margin-left: 15px;">
					<p style="margin: 0; margin-right: 10px;">预约开关</p>
					<van-switch v-model="formdata.status" active-color="#ee0a24" size="20" :active-value="1"
						:inactive-value="0" @change="statusVal(value)" />
				</div>
				<div style="margin-top:2rem;">
					<button
						style="width: 100%;height: 40px;background-color: #ee0a24;border: 1px solid #ee0a24;border-radius: 20px;color: #FFFFFF;"
						@click='EdtalList'>编辑</button>
				</div>
			</van-form>
		</van-popup>
	</div>
</template>

<script>
	import {
		makeList,
		makeAdd,
		uploadImage,
		makeDel,
		makeInfo,
		makeEdit,
		userAppoint,
		info,
		changest
	} from '@/api/make'
	import {
		Toast,
		Dialog
	} from "vant";
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				goodsList: [], // 请求数据
				name: '',
				intro: '',
				status: 1,
				add: false,
				image: [],
				formdata: {
					name: '',
					intro: '',
					image: [],
					status: 0
				},
				imageUrl: http.imageUrl,
				info: false,
				lid: '',
				formdatas: {
					is_appoint: 0
				}
			}
		},
		methods: {
			afterDelete(file, detail) {
				switch (detail.name) {
					case 'image':
						this.formdata.image = ''
						this.image.splice(detail.index, 1)
						break
				}
			},
			afterRead(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					// console.log(res)
					if (detail.name === 'image') {
						this.formdata.image = res.data.path
					}
				})
			},
			getList() {
				makeList().then((res) => {
					console.log(res)
					this.goodsList = res.data
					console.log(this.goodsList)
					// this.goodsList = res.data
				})
			},
			addMake() {
				this.add = true

			},
			statusVal(value) {
				// console.log(this.formdata.status)
				// 
			},
			statusVals(value) {
				let params = {
					field: 'is_appoint',
					status: this.formdata.is_appoint
				}
				changest(params).then((res) => {
					// console.log(res)
					Toast({
						message: res.msg,
					});
					this.getList()
					location.reload();
				})
			},
			infos() {
				info().then((res) => {
					if (res.data.info.is_appoint == 1) {
						this.getList()
						this.formdata.is_appoint = res.data.info.is_appoint
					} else {
						Dialog.confirm({
								title: '预约',
								message: '是否开启预约功能',
							})
							.then(() => {
								let params = {
									field: 'is_appoint',
									status: 1
								}
								changest(params).then((res) => {
									// console.log(res)
									Toast({
										message: res.msg,
									});
									this.getList()
									location.reload();
								})
							})
							.catch(() => {
								this.$router.go(-1)
							});
					}
				})
			},
			addList() {
				let params = {
					name: this.formdata.name,
					intro: this.formdata.intro,
					image: this.formdata.image,
					status: this.formdata.status,
				}
				makeAdd(params).then((res) => {
					// console.log(res)
					Toast({
						message: res.msg,
					});
					this.getList()
					this.add = false
				})
			},
			detail(id) {
				// console.log(id)
				this.lid = id
				this.info = true
				let params = {
					id: this.lid
				}
				makeInfo(params).then((res) => {
					// console.log(res)
					this.formdata.name = res.data.name
					this.formdata.intro = res.data.intro
					this.formdata.status = res.data.status
					if (res.data.image) {
						this.image = [{
							url: 'https://server.esw235.cn/' + res.data.image
						}]
					}
				})
			},
			EdtalList() {
				let params = {
					id: this.lid,
					name: this.formdata.name,
					intro: this.formdata.intro,
					image: this.formdata.image,
					status: this.formdata.status
				}
				makeEdit(params).then((res) => {
					// console.log(res)
					Toast({
						message: res.msg,
					});
					this.getList()
					this.info = false
					// location.reload();
				})
			},
			// 删除
			del(id) {
				let params = {
					id: id
				}
				makeDel(params).then((res) => {
					// console.log(res)
					Toast({
						message: res.msg,
					});
					this.getList()
				})
			},
			// 
			AnList(id) {
				this.$router.push({
					path: '/user/list',
					query: {
						aid: id
					}
				})
			},
		},
		mounted() {
			this.infos()
		}
	}
</script>

<style lang="less" scoped>
	.make {
		width: 100%;
		min-height: calc(100vh);
		position: relative;

		.top {
			display: flex;
			position: absolute;
			bottom: 10px;
			left: calc(50% - 100px);

			.add {
				color: #fff;
				background-color: #ee0a24;
				border: 1px solid #ee0a24;
				width: 70px;
				height: 35px;
				border-radius: 17px;
			}
		}

		.goods-item {
			background-color: #FFFFFF;
			// height: 170px;
			border-radius: 5px;
			margin-bottom: 10px;
			font-size: 14px;
			padding: 0 10px;

			.abc {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.switvh {
				display: flex;
				align-items: center;

				p {
					margin: 0;
					margin-right: 10px;
				}
			}
		}

		.pass-form {
			width: 18rem;
			padding: 2rem 1rem;
		}
	}

	.van-field__label {
		width: 2rem;
	}

	.van-button {
		height: 100% !important;
	}
</style>
